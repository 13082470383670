<script lang="ts">
  interface Props {
    title?: string | null;
    titleValue?: string | null;
    description?: string | null;
    author?: string | null;
    image?: string | null;
    imageAlt?: string | null;
    imageWidth?: number | null;
    imageHeight?: number | null;
    keywords?: string[] | null;
    site?: string | null;
    type?: string | null;
    canonical?: string | null;
  }

  let {
    title = null,
    titleValue = null,
    description = null,
    author = null,
    image = null,
    imageAlt = null,
    imageWidth = null,
    imageHeight = null,
    keywords = null,
    site = null,
    type = null,
    canonical = null
  }: Props = $props();
</script>

<svelte:head>
  {#if titleValue}
    <title>{titleValue}</title>
  {:else if title}
    <title>{title}</title>
  {/if}

  {#if title}
    <meta name="twitter:title" content={title} />
    <meta property="og:title" content={title} />
  {/if}

  {#if description}
    <meta name="description" content={description} />
    <meta name="twitter:description" content={description} />
    <meta property="og:description" content={description} />
  {/if}

  {#if canonical}
    <link rel="canonical" href={canonical} />
    <meta name="url" content={canonical} />
    <meta property="og:url" content={canonical} />
  {/if}

  {#if author}
    <meta name="author" content={author} />
  {/if}

  {#if site}
    <meta name="twitter:site" content={site} />
  {/if}

  {#if type}
    <meta property="og:type" content={type} />
  {/if}

  {#if image}
    <!-- Link preview -->
    <link rel="image_src" href={image} />
    <meta property="og:image" content={image} />
    <!-- twitter card large image has same dimensions as facebook's recommended: 1200x630 -->
    <meta name="twitter:image" content={image} />
    <meta name="twitter:card" content="summary_large_image" />

    {#if imageWidth && imageHeight}
      <meta property="og:image:width" content={imageWidth.toString()} />
      <meta property="og:image:height" content={imageHeight.toString()} />
    {/if}

    {#if title}
      <!-- Meta image alt text //www.stefanjudis.com/today-i-learned/how-to-define-open-graph-twitter-image-alt-text-and-why-it-might-not-matter -->
      <meta name="twitter:image:alt" content={imageAlt ?? title} />
      <meta property="og:image:alt " content={imageAlt ?? title} />
    {/if}
  {/if}

  {#if keywords && keywords.length}
    <!-- Remove keywords duplicates and make comma separated string -->
    <meta name="keywords" content={[...new Set(keywords)].join(", ")} />
  {/if}
</svelte:head>
