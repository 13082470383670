<script lang="ts">
  import type { MetaData } from "$lib/types/metaData";
  import { page } from "$app/stores";
  import MetaTags from "./MetaTags.svelte";
  import JsonLd from "./JsonLd.svelte";

  const getTitle = (title = data?.title) => {
    // No title: return "Ishavskraft", append pathname if not root
    if (!title) {
      return `Ishavskraft${$page?.url?.pathname !== "/" ? ` – ${$page?.url?.pathname}` : ""}`;
    } else {
      title = title.trim();
      // remove trailing period
      title = title?.replace(/\.$/, "");
      // if title does not include "ishavskraft", append "– Ishavskraft"
      if (!title.match(/ishavskraft/gi)) title += " – Ishavskraft";
      return title;
    }
  };

  const getCanonicalUrl = (): string => {
    // TODO: consider how to use a config variable for origin. `$page.url.origin` is not suitable as it reflects any origin (for any branch preview)
    // return data?.canonical || `${$page?.url?.origin}${$page?.url?.pathname || ""}`;
    return data?.canonical || `https://www.ishavskraft.no${$page?.url?.pathname || ""}`;
  };

  interface Props {
    data?: MetaData | null;
    jsonLd?: boolean;
  }

  let { data = null, jsonLd = false }: Props = $props();
</script>

<!-- TODO: add twitter meta value (same for all pages) -->
<MetaTags
  title={(data?.title || getTitle()).trim()}
  titleValue={getTitle()}
  description={data?.description}
  author={data?.author}
  image={data?.image ||
    "https://cdn.sanity.io/images/n5xeg4df/development/99b52faa335f5f26d3a5c5cbc58c48155aed284b-4592x3056.jpg?rect=0,323,4592,2411&w=1200&h=630&fm=jpg&q=80&fit=crop"}
  imageWidth={data?.imageWidth}
  imageHeight={data?.imageHeight}
  keywords={data?.keywords}
  site={data?.site}
  type={data?.type || "article"}
  canonical={getCanonicalUrl()}
/>

{#if jsonLd}
  <JsonLd {data} />
{/if}
