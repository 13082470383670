<script lang="ts">
  import { page } from "$app/stores";

  type JsonLdValue = string | Record<string, string | Date | undefined> | JsonLdValue[];
  type JsonLdObject = Record<string, JsonLdValue>;

  const getJsonLdScript = (title): string => {
    const obj = getJsonLdObject(title);
    return `<script type="application/ld+json">${JSON.stringify(obj)}${decodeURI(`%3C/script%3E`)}`;
  };

  interface Props {
    data?: {
    title?: string | null;
    description?: string | null;
    author?: string | null;
    image?: string | null;
    imageAlt?: string | null;
    type?: string | null;
    datePublished?: string | null;
    mainEntity?: unknown | null;
    price?: number | null; // Ordinary price
    campaign?: {
      name: string | null;
      price: number | null;
      validFrom?: string | null;
      validThrough?: string | null;
    } | null;
  } | null;
  }

  let { data = null }: Props = $props();

  // title is data.title or if root, Ishavskraft, else Ishavskraft – [URL]
  let title =
    $derived(data?.title || `Ishavskraft${$page?.url?.href !== "/" ? ` – ${$page?.url?.href}` : ""}`);

  const getJsonLdObject = (title): JsonLdObject => {
    const type = data?.type
      ? `${data.type.charAt(0).toUpperCase()}${data.type.slice(1)}`
      : "WebPage";

    let obj: Record<string, JsonLdValue> = {
      "@context": "https://schema.org",
      "@type": type,
    };

    // https://schema.org/Thing
    obj.name = title;
    if ($page?.url?.href) obj.url = $page?.url?.href;
    // headline | title
    if (type.match(/article/i)) obj.headline = title;

    // pricing
    if (type.match(/product/i)) {
      obj.offers = [];
      const ordinary: JsonLdValue = {
        "@type": "Offer",
        availability: "https://schema.org/InStock",
        priceCurrency: "NOK",
        price: data?.price?.toFixed(2) || "0",
        itemCondition: "https://schema.org/NewCondition",
      };
      if (data?.campaign) ordinary.name = "Ordinær pris";
      obj.offers.push(ordinary);

      if (data?.campaign) {
        const campaign: JsonLdValue = {
          "@type": "Offer",
          name: data.campaign.name || "Kampanjepris",
          price: data.campaign.price?.toFixed(2) ?? "",
          priceCurrency: "NOK",
          availability: "https://schema.org/InStock",
          itemCondition: "https://schema.org/NewCondition",
        };
        if (data.campaign.validFrom)
          campaign.validFrom = new Date(data.campaign.validFrom).toISOString();
        if (data.campaign.validThrough)
          campaign.validThrough = new Date(data.campaign.validThrough).toISOString();
        obj.offers.push(campaign);
      }
    }

    if (data?.description) obj.description = data.description;
    if (data?.image) obj.image = data.image;
    if (data?.datePublished) obj.datePublished = new Date(data.datePublished).toISOString();
    if (data?.mainEntity) obj.mainEntity = data.mainEntity;
    // Add one or more authors
    if (data?.author && data.author.match(/,+?/g)) {
      obj.author = [
        ...data.author.split(",").map((author) => ({
          "@type": "Person",
          name: author.trim(),
        })),
      ];
    } else if (data?.author) {
      obj.author = {
        "@type": "Person",
        name: data.author,
      };
    }

    return obj;
  };
</script>

<svelte:head>
  <!-- eslint-disable-next-line svelte/no-at-html-tags -->
  {@html getJsonLdScript(title)}
</svelte:head>
